<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
      <v-layout fluid flex flex-wrap justify-center class="d-flex">
        <v-flex d-flex xs12 sm5 align-center justify-center elevation-0 class="white">
          <v-card flat class="v-login-box rounded-0" width="100%">
            <div v-if="showCurrPass" class="d-flex mb-16 pb-3">
              <a href="/settings" class="text-decoration-none black--text"
                ><v-icon small left> arrow_back </v-icon> {{ $t('changePass.backSett') }}</a
              >
            </div>
            <v-layout class="flex-column">
              <h1 class="font-weight-medium mb-2 black-text">{{ $t('changePass.chngPass') }}</h1>
            </v-layout>
            <v-card-text class="py-4 px-0">
              <p v-if="true">
                <strong>{{ msg }}</strong>
              </p>
              <v-form v-model="valid" ref="form" :action="action" method="POST" role="form">
                <input type="hidden" name="csrf_token" :value="csrf_token">
                <label class="black--text mb-2 d-flex">{{ $t('changePass.newPass') }}</label>
                <v-text-field
                  label="Enter new password"
                  v-model="newPassword"
                  :append-icon="newToggle ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="newToggle ? 'text': 'password'"
                  @click:append="newToggle = !newToggle"
                  min="6"
                  :rules="passwordRules"
                  required
                  outlined
                  dense
                  name="password"
                ></v-text-field>

                <label class="black--text mb-2 d-flex">{{ $t('changePass.confirmPass') }}</label>
                <v-text-field
                  label="Confirm new password"
                  v-model="confirmPassword"
                  :append-icon="confirmToggle ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="confirmToggle ? 'text': 'password'"
                  @click:append="confirmToggle = !confirmToggle"
                  min="6"
                  :rules="confirmRules"
                  required
                  outlined
                  dense
                ></v-text-field>

                <v-layout justify-end flex-wrap>
                  <v-btn type='submit'
                    block
                    depressed large
                    class="primary white--text mb-7"
                    :disabled="!valid"
                  >{{ $t('changePass.submit') }}</v-btn>
                </v-layout>

              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm7
          align-center
          justify-center
          elevation-0
          blue
          accent-4
          class="v-login-right"
        >
          <v-card flat class="v-login-right-inner">
            <div class="pt-4 white--text">
              <h4 class="font-weight-medium pb-6">
                {{ $t('changePass.h4') }}
              </h4>
              <p class="white-text">
                {{ $t('changePass.para') }}
              </p>
            </div>
            <v-card-text class="pt-4 px-0 white--text">
              <v-img src="../assets/images/login-img.svg"></v-img>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="text-center">
        <v-dialog
          v-model="msgModel"
          width="300"
          persistent
        >
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ msgTitle }}
            </v-card-title>

            <v-card-text>
              {{ message }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="goToDashboard"
              >
                {{ $t('changePass.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </div>
</template>

<script >
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { LSS } from '@core/services';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config'

export default Vue.extend({
  name: 'ChangePassword',
  components: {
  },

  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      currPassword: null,
      newToggle: false,
      confirmToggle: false,
      currToggle: false,
      valid:null,
      flow:null,
      action:null,
      csrf_token : null,
      showmsg: false,
      dialog:false,
      msg:"No msg",
      success: false,
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be atleast 6 characters',
      ],
      currRules: [
        v => !!v || 'Current Password is required',
        v => (v && v.length >= 6) || 'Password must be atleast 6 characters',
      ],
      confirmRules: [
        v => !!v || 'Confirm Password is required',
        v => (v && v.length >= 6) || 'Password must be atleast 6 characters',
        v => (v && v==this.newPassword) || 'Passwords do not match'
      ],
      loading: false,
      msgModel: false,
      message :'',
      msgTitle: '',
      showCurrPass: false,
    };
  },
  methods: {
    getRecoveryFlowStatus: async function (){
      this.msg = '';
      this.flow = this.$route.query.flow;
      await FlowService.flow({ flowId: this.flow, type:'settings' })
      .then((res) => {
        //console.log("FlowServiceRes--->",res);
        this.action = res.data.action;
        this.csrf_token = res.data.token;
        if(res.data.state == 'show_form'){
          this.showmsg = true;
          if(res.data.message) this.msg=res.data.message[0].text;
          if(res.data.weak) this.msg=res.data.weak;
        }
        else if(res.data.state == 'success'){
          //console.log("state check--->",res.data.state);
          this.showmsg = true;
          this.msg='Link already used';
          this.msgModel = true;
          this.msgTitle = 'Success!!!'
          this.message = 'Password changed successfully'
          //setTimeout(() => {(this.msgModel = false);}, 1000)
          // console.log(this.msg);
        }
        else{
          this.showmsg = true;
          this.msg= 'Something seems wrong. Try later';
        }
      })
      .catch((err) => {
        //console.log(err)
        //console.log(err.response)
        this.showmsg = true;
        this.msg= 'Something seems wrong. Try later';
      });
    },
    goToDashboard() {
      if(LSS.token && LSS.sessId){
        return this.$router.push('/overview');
      }else{
        window.location.href = API_ORY_URL + 'self-service/browser/flows/logout'
      }
    }
  },
  beforeMount(){
    this.getRecoveryFlowStatus()
  },
  mounted() {
    if(LSS.token && LSS.sessId){
      this.showCurrPass = true;
    }else{
      this.showCurrPass = false;
      this.currPassword = 'ert345yu56'
    }
  }
});
</script>
